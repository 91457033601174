import styled from 'styled-components';
import BgImage from 'common/assets/image/saas/map.png';
import { themeGet } from '@styled-system/theme-get';

const ContactSectionWrapper = styled.section`
  padding: 120px 0;
  background-color: #fafbff;
  background-image: url(${BgImage});
  background-repeat: no-repeat;
  background-position: 25% center;

  @media (max-width: 990px) {
    padding: 100px 0;
  }
  @media (max-width: 768px) {
    padding: 80px 0;
  }
  @media (max-width: 575px) {
    padding: 60px 0 220px;
  }

  .text_input {
    flex-grow: 1;
    margin-right: 20px;
    textarea {
      margin-bottom: 20px;
    }
    @media (max-width: 575px) {
      width: 100%;
      margin-right: 0;
      margin-bottom: 20px;
    }
    &.is-material {
      &.is-focus {
        label {
          color: #aeb1b6;
          font-size: 12px;
        }
      }
    }

    input,
    textarea {
      height: 100%;
      background: #fff;
      font-size: 16px;
      font-weight: 400;
      color: #343d48;
      padding: 5px 15px;
      border-color: #dadada;
      @media (max-width: 575px) {
        height: 48px;
      }
      margin-bottom: 20px;
    }

    label {
      font-size: 14px;
      color: #343d48;
      font-weight: 500;
      padding-left: 10px;
      top: 5px;
    }
  }
`;

const AddressWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  @media (max-width: 768px) {
    justify-content: space-evenly;
    margin-bottom: 15px;
    height: 7.1em;
  }
`;
const ContactInfoWrapper = styled.div`
  min-width: 18em;
  display: flex;
  justify-content: space-evenly;
  border-radius: 4em;
  height: 7rem;
  align-items: center;
  border: 1px solid ${themeGet('colors.lightBorder', '#f1f4f6')};
  box-shadow: 0px 7px 25px 0px rgba(22, 53, 76, 0.05);
  margin: 0 0 5rem 0;
  background-color: ${themeGet('colors.white', '#ffffff')};
  @media (max-width: 990px) {
    padding: 1rem 0;
    margin: 0 -6rem;
  }
  @media (max-width: 768px) {
    height: 21em;
    width: 25em;
    padding: 1rem 0;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
  }
  @media (max-width: 480px) {
    height: 21em;
    flex-direction: column;
    width: unset;
    margin-left: unset;
    margin-right: unset;
  }
`;

const RoundedButton = styled.button`
  background: #fff;
  border-radius: 50px;
  border: 1px solid #906a6f;
  font-weight: bold;
  color: #906a6f;
  padding: 0.5rem 1rem 0.5rem 1.4rem;
  font-size: 1.1em;
  transition: all 0.3s ease 0s;
  &:hover {
    background: #906a6f;
    color: #fff;
  }
  margin-right: 0.5rem;
  width: 3.6em;
`;

const RoundedMapButton = styled.button`
  background: #fff;
  border-radius: 50px;
  border: 1px solid #906a6f;
  font-weight: bold;
  color: #906a6f;
  padding: 0.2em 0.8em 0.2em 0.7em;
  font-size: 2.6em;
  transition: all 0.3s ease 0s;
  &:hover {
    background: #906a6f;
    color: #fff;
  }
  margin-right: 0.5rem;
  width: 2.4em;
`;

const RoundedPhoneButton = styled.a`
  width: 13em;
  display: flex;
  background: #fff;
  border-radius: 50px;
  border: 1px solid #906a6f;
  font-weight: bold;
  color: #906a6f;
  padding: 0.5rem 1rem;
  font-size: 1.1em;
  transition: all 0.3s ease 0s;
  &:hover {
    background: #906a6f;
    color: #fff;
  }
  margin-right: 0.5rem;
  justify-content: center;
  flex-direction: row;
  align-items: center;
`;

const PhoneWrapper = styled.div`
  width: 12em;
  display: flex;
  background: #fff;
  font-weight: bold;
  color: #906a6f;
  font-size: 1.1em;
  margin-right: 0.5rem;
  justify-content: center;
  flex-direction: row;
  align-items: center;
`;
export default ContactSectionWrapper;
export {
  AddressWrapper,
  ContactInfoWrapper,
  RoundedButton,
  RoundedPhoneButton,
  PhoneWrapper,
  RoundedMapButton,
};
