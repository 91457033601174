import styled from 'styled-components';
import { themeGet } from '@styled-system/theme-get';
import BannerBG from 'common/assets/image/saas/saas-banner.jpg';

const BannerWrapper = styled.section`
  display: flex;
  // margin: 0 20em 0 20em;
  margin: 0 auto 0 auto;
  width: 60%;
  flex-direction: row;
  align-items: center;
  padding-top: 12em;
  padding-bottom: 100px;
  // background-image: url(${BannerBG});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  position: relative;
  overflow: hidden;
  @media (max-width: 2000px) {
    width: 80%;
  }
  @media (max-width: 990px) {
    flex-direction: column;
    padding-top: 10em;
    padding-bottom: 60px;
    min-height: auto;
    text-align: center;
    width: 80%;
  }
  @media (max-width: 768px) {
    flex-direction: column;
    padding-top: 8em;
    padding-bottom: 20px;
    min-height: auto;
    text-align: center;
  }

  @media only screen and (max-width: 480px) {
    background: none;
  }

  .particle {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    overflow: hidden;
    @media (max-width: 768px) {
      display: none;
    }
    @media only screen and (max-width: 480px) {
      width: 100%;
    }
  }

  .row {
    position: relative;
    z-index: 1;
  }

  .button__wrapper {
    display: flex;
    margin-top: 40px;
    @media (max-width: 768px) {
      margin-bottom: 30px;
    }
    .reusecore__button {
      &.outlined {
        border-color: rgba(82, 104, 219, 0.2);
      }
    }
  }
`;

// const BannerObject = styled.div`
//   // @media (max-width: 768px) {
//   //   display: none;
//   // }
//   /* Extra small devices (phones, 600px and down) */
//   @media only screen and (max-width: 600px) {
//     background: none;
//   }

//   /* Small devices (portrait tablets and large phones, 600px and up) */
//   @media only screen and (min-width: 600px) {...}

//   /* Medium devices (landscape tablets, 768px and up) */
//   @media only screen and (min-width: 768px) {...}

//   /* Large devices (laptops/desktops, 992px and up) */
//   @media only screen and (min-width: 992px) {
//     // position: absolute;
//     // width: 46%;
//     // height: 100%;
//     // top: -100px;
//     // right: 0;
//     // display: flex;
//     // align-items: center;
//   }

//   /* Extra large devices (large laptops and desktops, 1200px and up) */
//   @media only screen and (min-width: 1200px) {
//     position: absolute;
//     width: 588px;
//     height: 800px;
//     top: 60px;
//     right: 3em;
//     display: flex;
//     align-items: center;
//   }
// `;

const SorrirLabel = styled.div`
  display: inline-block;
  border-radius: 4em;
  border: 1px solid ${themeGet('colors.lightBorder', '#f1f4f6')};
  padding: 7px 25px;
  box-shadow: 0px 7px 25px 0px rgba(22, 53, 76, 0.05);
  margin-bottom: 30px;
  background-color: ${themeGet('colors.white', '#ffffff')};
  @media (max-width: 768px) {
    padding: 7px 15px;
    margin-top: 2rem;
  }
`;

export { SorrirLabel };
// export { SorrirLabel, BannerObject };

export default BannerWrapper;
