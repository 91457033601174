import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import Box from 'common/components/Box';
import Text from 'common/components/Text';
import Image from 'common/components/Image';
import Heading from 'common/components/Heading';
import Button from 'common/components/Button';
import FeatureBlock from 'common/components/FeatureBlock';
import Container from 'common/components/UI/Container';
import Particles from '../Particle';
import BannerWrapper, {
  SorrirLabel,
  // BannerObject,
} from './bannerSection.style';

import BannerObject2 from 'common/assets/image/_oral_prime/Depositphotos_25309161_s-2019.jpg';

const BannerSection = ({
  row,
  col,
  title,
  btnStyle,
  description,
  sorrirText,
  primeiroSorrir,
  segundoSorrir,
  terceiroSorrir,
  outlineBtnStyle,
  imageArea,
}) => {
  const ButtonGroup = () => (
    <Fragment>
      <a href="#contact_section">
        <Button title="CONTATO" {...btnStyle} />
      </a>
      <a href="#feature_section">
        <Button
          className="outlined"
          title="SAIBA MAIS"
          variant="outlined"
          {...outlineBtnStyle}
        />
      </a>
    </Fragment>
  );
  return (
    <BannerWrapper id="banner_section">
      <Particles />
      <Container>
        <Box className="row" {...row}>
          <Box className="col" {...col}>
            <SorrirLabel>
              <Text content="Sorrir" {...primeiroSorrir} />
              <Text content="com vontade. " {...sorrirText} />
              <Text content="Sorrir" {...segundoSorrir} />
              <Text content="com paixão. " {...sorrirText} />
              <Text content="Sorrir" {...terceiroSorrir} />
              <Text content="com liberdade. " {...sorrirText} />
            </SorrirLabel>
            <FeatureBlock
              title={
                <Heading
                  as="h1"
                  content="Você merece 
                    um Sorriso de
                    Primeira"
                  {...title}
                />
              }
              description={
                <Text
                  content="Na Oral Prime oferecemos desde os tratamentos convencionais aos mais modernos e complexos para que você possa ter o sorriso que deseja."
                  {...description}
                />
              }
              button={<ButtonGroup />}
            />
          </Box>
        </Box>
      </Container>
      <Box {...col}>
        <Image src={BannerObject2} alt="Woman smiling" {...imageArea} />
      </Box>
    </BannerWrapper>
  );
};

BannerSection.propTypes = {
  title: PropTypes.object,
  btnStyle: PropTypes.object,
  description: PropTypes.object,
  contentStyle: PropTypes.object,
  primeiroSorrir: PropTypes.object,
  segundoSorrir: PropTypes.object,
  terceiroSorrir: PropTypes.object,
  discountAmount: PropTypes.object,
  outlineBtnStyle: PropTypes.object,
};

BannerSection.defaultProps = {
  imageArea: {
    width: '601px',
    // ml: '-2em !important',
    // mr: '-2em !important',
    ml: 'auto !important',
    mr: 'auto !important',
  },
  row: {
    flexBox: true,
    flexWrap: 'wrap',
    ml: '-15px',
    mr: '-15px',
    alignItems: 'center',
  },
  col: {
    ml: 'auto',
    mr: 'auto',
    width: ['100%', '82%', '68%', '80%', '80%'],
    mb: '2em',
  },
  title: {
    fontSize: ['22px', '34px', '30px', '55px'],
    fontWeight: '700',
    color: '#0f2137',
    letterSpacing: '-0.025em',
    mb: ['20px', '25px'],
    lineHeight: '1.5',
  },
  description: {
    fontSize: '16px',
    color: '#343d48cc',
    lineHeight: '1.75',
    mb: '0',
  },
  btnStyle: {
    minWidth: ['0', '120px', '120px', '156px'],
    fontSize: ['13px', '14px'],
    fontWeight: '500',
    colors: 'primaryWithBg',
  },
  outlineBtnStyle: {
    minWidth: '156px',
    fontSize: '14px',
    fontWeight: '500',
    color: '#b8a156',
    // colors: 'secondaryWithBg',
    ml: '18px',
  },
  primeiroSorrir: {
    fontSize: '14px',
    color: '#B8A156',
    mb: 0,
    as: 'span',
    mr: '0.4em',
    fontWeight: 700,
  },
  segundoSorrir: {
    fontSize: '14px',
    color: '#906a6f',
    mb: 0,
    as: 'span',
    mr: '0.4em',
    fontWeight: 700,
  },
  terceiroSorrir: {
    fontSize: '14px',
    color: '#53788A',
    mb: 0,
    as: 'span',
    mr: '0.4em',
    fontWeight: 700,
  },
  sorrirText: {
    fontSize: ['13px', '14px'],
    color: '#0f2137',
    mb: 0,
    as: 'span',
    fontWeight: 500,
  },
};

export default BannerSection;
