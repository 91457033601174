import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import Box from 'common/components/Box';
import Text from 'common/components/Text';
import Link from 'common/components/Link';
import Container from 'common/components/UI/Container';

import ContactSectionWrapper, {
  ContactInfoWrapper,
  AddressWrapper,
  RoundedButton,
  RoundedPhoneButton,
  PhoneWrapper,
  RoundedMapButton,
} from './contact.style';

const ContactSection = ({
  row,
  mapButton,
  phoneText,
  addressText,
  visibleHighResolution,
  visibleLowResolution,
}) => {
  const Whatsapp = () => (
    <Fragment>
      <i className="flaticon flaticon-whatsapp-6"></i>
      <Text {...phoneText} content="(77) 9.9191-6677" />
    </Fragment>
  );
  const Phonenumber = () => (
    <Fragment>
      <i className="flaticon flaticon-phone-2"></i>
      <Text {...phoneText} content="(77) 3611-7766" />
    </Fragment>
  );

  return (
    <ContactSectionWrapper id="contact_section">
      <Container>
        <ContactInfoWrapper>
          <Link
            href="https://goo.gl/maps/7xYHnekYaHM9uqFW8"
            target="_blank"
            rel="noreferrer"
          >
            <RoundedMapButton
              href="https://goo.gl/maps/7xYHnekYaHM9uqFW8"
              target="_blank"
              rel="noopener"
              {...mapButton}
            >
              <i className="flaticon flaticon-map-6"></i>
            </RoundedMapButton>
          </Link>
          <Box>
            <Text
              {...addressText}
              {...visibleHighResolution}
              content="R. D. Pedro II, 300 - Sandra Regina"
            />
            <Text
              {...addressText}
              {...visibleLowResolution}
              content="R. D. Pedro II, 300"
            />
            <Text
              {...addressText}
              {...visibleLowResolution}
              content="Sandra Regina"
            />
            <Text {...addressText} content="Barreiras - BA, 47803-240" />
          </Box>
          <AddressWrapper>
            <Box {...row}>
              <Box {...visibleLowResolution}>
                <Link href="tel:(77) 3611-7766">
                  <RoundedPhoneButton href="tel:(77) 3611-7766">
                    <Phonenumber />
                  </RoundedPhoneButton>
                </Link>
              </Box>
              <Box {...visibleHighResolution}>
                <PhoneWrapper>
                  <Phonenumber />
                </PhoneWrapper>
              </Box>
            </Box>

            <Box {...row}>
              <Box {...visibleLowResolution}>
                <Link
                  href="https://wa.me/5577991916677"
                  target="_blank"
                  rel="noopener"
                >
                  <RoundedPhoneButton
                    href="https://wa.me/5577991916677"
                    target="_blank"
                    rel="noopener"
                  >
                    <Whatsapp></Whatsapp>
                  </RoundedPhoneButton>
                </Link>
              </Box>
              <Box {...visibleHighResolution}>
                <PhoneWrapper>
                  <Whatsapp></Whatsapp>
                </PhoneWrapper>
              </Box>
            </Box>
          </AddressWrapper>
          <Box>
            <Link
              href="mailto:contato@clinicaoralprime.com.br"
              target="_blank"
              rel="noreferrer"
            >
              <RoundedButton
                href="mailto:contato@clinicaoralprime.com.br"
                target="_blank"
                rel="noopener"
              >
                <i className="flaticon flaticon-mail"></i>
              </RoundedButton>
            </Link>
            <Link
              href="https://www.facebook.com/oralprimebarreiras"
              target="_blank"
              rel="noreferrer"
            >
              <RoundedButton
                href="https://www.facebook.com/oralprimebarreiras"
                target="_blank"
                rel="noopener"
              >
                <i className="flaticon flaticon-facebook-6"></i>
              </RoundedButton>
            </Link>
            <Link
              href="https://www.instagram.com/oral_prime_odontologia_"
              target="_blank"
              rel="noreferrer"
            >
              <RoundedButton
                href="https://www.instagram.com/oral_prime_odontologia_"
                target="_blank"
                rel="noopener"
              >
                <i className="flaticon flaticon-instagram-5"></i>
              </RoundedButton>
            </Link>
          </Box>
        </ContactInfoWrapper>
      </Container>
    </ContactSectionWrapper>
  );
};

ContactSection.propTypes = {
  row: PropTypes.object,
  col: PropTypes.object,
  imageAreaRow: PropTypes.object,
  title: PropTypes.object,
  description: PropTypes.object,
  button: PropTypes.object,
  mapButton: PropTypes.object,
  textArea: PropTypes.object,
  addressArea: PropTypes.object,
  input: PropTypes.object,
};

ContactSection.defaultProps = {
  secTitleWrapper: {
    mb: ['45px', '50px', '60px'],
  },
  secText: {
    as: 'span',
    display: 'block',
    textAlign: 'center',
    fontSize: `${2}`,
    letterSpacing: '0.15em',
    fontWeight: `${6}`,
    color: 'primary',
    mb: `${3}`,
  },
  phoneText: {
    as: 'span',
    display: 'block',
    textAlign: 'center',
    fontSize: `${3}`,
    letterSpacing: '0.15em',
    fontWeight: `${6}`,
    mb: `${0}`,
    ml: `${2}`,
    lineHeight: '1.8rem',
  },
  addressText: {
    as: 'span',
    display: 'block',
    textAlign: 'center',
    fontSize: `${2}`,
    letterSpacing: '0.15em',
    fontWeight: `${3}`,
    color: '#906a6f',
    margin: '.5rem .5rem !important',
  },
  secHeading: {
    textAlign: 'center',
    fontSize: [`${6}`, `${8}`],
    fontWeight: '400',
    color: 'headingColor',
    letterSpacing: '-0.025em',
    mb: `${0}`,
  },
  row: {
    flexBox: true,
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent: 'center',
  },
  socialMediaWrap: {
    flexBox: true,
    flexWrap: 'wrap',
    alignItems: 'center',
  },
  imageAreaRow: {
    flexDirection: 'row-reverse',
  },
  spaceRow: {
    justifyContent: 'space-around',
  },
  textArea: {
    width: ['100%', '100%', '55%', '50%', '42%'],
  },
  input: {
    width: ['100%', '100%', '55%', '50%', '42%'],
    mt: ['40px', '40px', '0', '0', '0'],
    mb: ['40px', '40px', '10px', '10px', '10px'],
  },
  imageArea: {
    width: ['100%', '100%', '45%', '50%', '58%'],
    mb: ['40px', '40px', '0', '0', '0'],
  },
  title: {
    fontSize: ['26px', '30px', '30px', '48px', '48px'],
    fontWeight: '400',
    color: '#0f2137',
    letterSpacing: '-0.025em',
    mb: '15px',
    lineHeight: '1.5',
  },
  description: {
    fontSize: '16px',
    color: '#343d48cc',
    lineHeight: '1.75',
    mb: '33px',
  },
  button: {
    type: 'button',
    fontSize: '14px',
    fontWeight: '600',
    color: '#fff',
    borderRadius: '4px',
    pl: '22px',
    pr: '22px',
    colors: 'primaryWithBg',
  },
  visibleHighResolution: {
    display: ['none', 'block', 'block', 'block', 'block'],
  },
  visibleLowResolution: {
    display: ['block', 'none', 'none', 'none', 'none'],
  },
};

export default ContactSection;
